@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@700&display=swap');

*:not(ul, ol) { margin: 0; padding: 0; }

:root {
    --url-color: #D5B1F2;
    --url-secondary-color: #90DA98;
    --text-color: #30333A;
    --text-inactive-color: #959595;
    --separator-color: #30333A;
    --bg-color-main: #F4F2EE;
    --bg-color-secondary: #E7F8FF;
    --bg-color-third: #E5E5E5;

    --heading-text-size: calc(1rem + 1vw);
    --section-heading-text-size: calc(0.8rem + 0.8vw);
    --subsection-heading-text-size: calc(1rem + 0.4vw);
    --main-text-size: calc(1rem + 0.2vw);
    --note-text-size: calc(0.8rem + 0.1vw);

    --main-padding: 30px;
    --half-padding: 15px;
    --border-radius: 20px;
    --border-radius-small: 12px;
}

body {
    background-color: var(--bg-color-main);
    font-family: 'Manrope', SansSerif, serif;
    color: var(--text-color);
    font-size: var(--main-text-size);
    font-weight: 500;
}

h1, h2, h3 { font-weight: 600; line-height: 1.5; }

h1 { font-size: var(--heading-text-size); }
h2 { font-size: var(--section-heading-text-size); }
h3 { font-size: var(--subsection-heading-text-size); }

p {
    font-size: var(--main-text-size);
    font-weight: 500;
    line-height: 1.5;
}

span {
    font-size: var(--note-text-size);
    font-weight: 500;
}

.inactive {
    color: var(--text-inactive-color);
    font-weight: 500;
}

a, .aClass {
    color: var(--url-color);
    text-decoration-style: dashed;
}


.a-black {
    color: var(--text-color);
    text-decoration-style: solid;
}

div, p {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.heading-text {
    font-size: var(--heading-text-size);
    line-height: 1.4;
}

.section-heading-text {
    font-size: var(--section-heading-text-size);
}

.subsection-heading-text {
    font-size: var(--subsection-heading-text-size);
}

/* UTILS */

.separator {
    height: 2px;
    border-bottom: var(--separator-color) 2px solid;
}

.shadow { box-shadow: 0 0 10px rgba(0,0,0,0.05); }

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

/* LAYOUT */

.wrapper {
    display: flex;
    justify-content: center;
    padding: var(--main-padding);
}

.container {
    width: 100%;
    height: 100vh;
}

/* HEADER */

.header {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.header-top {
    display: flex;
    justify-content: space-between;
    gap: 30px
}

.logo {
    width: calc(10rem + 10vw);
}

/* MAIN */

.main-page-container {
    display: flex;
    gap: 50px
}

.main-page-sidebar {
    width: 26%;
}


/* CARDS */

.cards-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: max(2%, 15px)
}

.card-container {
    flex-basis: 32%;
}

.card {
    background: #EBE9E6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 320px
}

.card-image-holder {
    max-width: 95%;
    max-height: 290px;
    padding: 15px;
    background-color: #FFF;
    box-shadow: 5px 5px 10px 0 #D3D3D3;
}

.card-image {
    max-width: 100%;
    max-height: 250px;
    border: 1px solid #CCC;
}

.content-block {
    background-color: var(--url-color);
    padding: 20px;
    border-color: #000000;
}

/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:1000px) {
    .card-container {
        flex-basis: 48%;
    }
}

@media (min-width: 0px) and (max-width:600px) {

    .card-container {
        flex-basis: 100%;
    }

    .content-block {
        padding: 10px;
    }
}


@media (min-width: 0px) and (max-width:450px) {
    :root {

        --main-padding: 20px;
        --half-padding: 10px;
    }

}

